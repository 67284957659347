import { err, ok, Result } from 'neverthrow'

export const lifeStatusValidator = (value: unknown): Result<true, string> => {
  const lifeStatusValue = String(value)

  if (lifeStatusValue !== 'A' && lifeStatusValue !== 'D') {
    return err('Life status must be either "A" or "D"')
  }

  return ok(true)
}
