import 'inter-ui/inter.css'
import '@fontsource/ibm-plex-mono'

import { ThemeProvider } from '@opengovsg/design-system-react'

import { MainPage } from './pages'
import { theme } from './theme'

function App() {
  return (
    <ThemeProvider theme={theme} resetCSS>
      <MainPage />
    </ThemeProvider>
  )
}

export default App
