import { extendTheme } from '@chakra-ui/react'
// Importing from main so @chakra-cli can work properly without complaining about ESM.
import { theme as baseTheme } from '@opengovsg/design-system-react/build/main/theme/theme'

import { colors } from './colors'
import { components } from './components'
import { shadows } from './shadows'
import { spacing } from './spacing'
import { textStyles } from './textStyles'
import { typography } from './typography'

/**
 * Design system themes can be found at
 * https://github.com/opengovsg/design-system/tree/main/token-gen/themes.
 * README for importing themes can be found at
 * https://github.com/opengovsg/design-system/tree/main/token-gen.
 */
export const theme = extendTheme(baseTheme, {
  styles: {
    global: {
      html: { height: '100%' },
      body: { height: '100%' },
      '#root': { height: 'inherit' },
    },
  },
  components,
  colors: colors,
  shadows: shadows,
  space: spacing,
  fontSizes: typography.fontSize,
  fontWeights: typography.fontWeights,
  lineHeights: typography.lineHeights,
  letterSpacings: typography.letterSpacing,
  textStyles,
})
