import { err, ok, Result } from 'neverthrow'

export const personOccupationValidator = (
  value: unknown,
): Result<true, string> => {
  const personOccupationValue = String(value)

  if (!PERSON_OCCUPATION_SSOC_CODES.includes(personOccupationValue)) {
    return err('Person occupation must have a valid SSOC code.')
  }

  return ok(true)
}

const PERSON_OCCUPATION_SSOC_CODES = [
  '11110',
  '11121',
  '11122',
  '11140',
  '11150',
  '11160',
  '11201',
  '11202',
  '11203',
  '12111',
  '12112',
  '12113',
  '12121',
  '12122',
  '12123',
  '12131',
  '12132',
  '12133',
  '12191',
  '12192',
  '12193',
  '12194',
  '12195',
  '12199',
  '12211',
  '12212',
  '12213',
  '12214',
  '12215',
  '12221',
  '12222',
  '12230',
  '12241',
  '12242',
  '13100',
  '13210',
  '13230',
  '13241',
  '13242',
  '13243',
  '13244',
  '13245',
  '13291',
  '13292',
  '13299',
  '13301',
  '13302',
  '13303',
  '13304',
  '13410',
  '13420',
  '13430',
  '13441',
  '13442',
  '13451',
  '13459',
  '13461',
  '13462',
  '13463',
  '13491',
  '13492',
  '13493',
  '13499',
  '14110',
  '14121',
  '14122',
  '14123',
  '14201',
  '14202',
  '14310',
  '14321',
  '14322',
  '14323',
  '14324',
  '14325',
  '14329',
  '14330',
  '14391',
  '14392',
  '14399',
  '21110',
  '21120',
  '21130',
  '21141',
  '21142',
  '21149',
  '21211',
  '21212',
  '21213',
  '21221',
  '21222',
  '21231',
  '21239',
  '21311',
  '21312',
  '21319',
  '21321',
  '21329',
  '21331',
  '21332',
  '21339',
  '21341',
  '21342',
  '21343',
  '21344',
  '21345',
  '21346',
  '21347',
  '21349',
  '21411',
  '21412',
  '21413',
  '21414',
  '21415',
  '21421',
  '21422',
  '21430',
  '21441',
  '21442',
  '21443',
  '21444',
  '21451',
  '21452',
  '21453',
  '21454',
  '21460',
  '21471',
  '21472',
  '21473',
  '21474',
  '21475',
  '21491',
  '21492',
  '21493',
  '21494',
  '21495',
  '21496',
  '21497',
  '21498',
  '21499',
  '21511',
  '21512',
  '21513',
  '21521',
  '21522',
  '21523',
  '21524',
  '21525',
  '21526',
  '21531',
  '21532',
  '21610',
  '21621',
  '21622',
  '21631',
  '21632',
  '21641',
  '21649',
  '21651',
  '21652',
  '21659',
  '21661',
  '21662',
  '21663',
  '21664',
  '21669',
  '21670',
  '21711',
  '21712',
  '21713',
  '21714',
  '21721',
  '21722',
  '21723',
  '21724',
  '21729',
  '22110',
  '22121',
  '22122',
  '22123',
  '22124',
  '22125',
  '22126',
  '22127',
  '22128',
  '22129',
  '22131',
  '22132',
  '22133',
  '22134',
  '22135',
  '22136',
  '22137',
  '22138',
  '22139',
  '22141',
  '22142',
  '22143',
  '22144',
  '22145',
  '22146',
  '22147',
  '22148',
  '22150',
  '22200',
  '22301',
  '22302',
  '22500',
  '22611',
  '22612',
  '22621',
  '22629',
  '22631',
  '22632',
  '22639',
  '22640',
  '22651',
  '22652',
  '22661',
  '22662',
  '22670',
  '22680',
  '22691',
  '22692',
  '22693',
  '22694',
  '22699',
  '23101',
  '23102',
  '23103',
  '23109',
  '23200',
  '23300',
  '23400',
  '23500',
  '23611',
  '23612',
  '23619',
  '23621',
  '23622',
  '23629',
  '23690',
  '24111',
  '24112',
  '24113',
  '24121',
  '24122',
  '24131',
  '24132',
  '24133',
  '24134',
  '24135',
  '24139',
  '24141',
  '24142',
  '24143',
  '24149',
  '24151',
  '24152',
  '24153',
  '24154',
  '24159',
  '24160',
  '24211',
  '24212',
  '24213',
  '24220',
  '24231',
  '24232',
  '24233',
  '24234',
  '24240',
  '24251',
  '24252',
  '24291',
  '24299',
  '24311',
  '24312',
  '24313',
  '24314',
  '24315',
  '24320',
  '24331',
  '24332',
  '24333',
  '24334',
  '24339',
  '24351',
  '24352',
  '24353',
  '24361',
  '24362',
  '25111',
  '25112',
  '25113',
  '25121',
  '25122',
  '25123',
  '25140',
  '25151',
  '25152',
  '25190',
  '25211',
  '25212',
  '25220',
  '25231',
  '25232',
  '25239',
  '25241',
  '25242',
  '25243',
  '25244',
  '25245',
  '25249',
  '25291',
  '25299',
  '26111',
  '26112',
  '26119',
  '26120',
  '26191',
  '26199',
  '26211',
  '26212',
  '26213',
  '26221',
  '26229',
  '26310',
  '26321',
  '26322',
  '26331',
  '26339',
  '26341',
  '26342',
  '26343',
  '26349',
  '26351',
  '26352',
  '26353',
  '26359',
  '26361',
  '26369',
  '26371',
  '26372',
  '26373',
  '26374',
  '26375',
  '26379',
  '26411',
  '26412',
  '26413',
  '26414',
  '26415',
  '26419',
  '26421',
  '26422',
  '26431',
  '26432',
  '26511',
  '26519',
  '26521',
  '26522',
  '26523',
  '26524',
  '26529',
  '26530',
  '26541',
  '26542',
  '26543',
  '26544',
  '26549',
  '26550',
  '26561',
  '26569',
  '31001',
  '31002',
  '31003',
  '31004',
  '31005',
  '31006',
  '31009',
  '31111',
  '31112',
  '31119',
  '31121',
  '31122',
  '31123',
  '31124',
  '31129',
  '31131',
  '31132',
  '31141',
  '31142',
  '31143',
  '31144',
  '31151',
  '31152',
  '31153',
  '31161',
  '31162',
  '31163',
  '31164',
  '31171',
  '31172',
  '31173',
  '31174',
  '31175',
  '31181',
  '31182',
  '31183',
  '31184',
  '31185',
  '31189',
  '31211',
  '31212',
  '31213',
  '31214',
  '31291',
  '31292',
  '31293',
  '31294',
  '31295',
  '31299',
  '31310',
  '31321',
  '31322',
  '31323',
  '31324',
  '31325',
  '31329',
  '31331',
  '31332',
  '31341',
  '31342',
  '31350',
  '31391',
  '31392',
  '31399',
  '31411',
  '31412',
  '31419',
  '31421',
  '31422',
  '31423',
  '31510',
  '31521',
  '31522',
  '31529',
  '31540',
  '31560',
  '31571',
  '31572',
  '31573',
  '31574',
  '31579',
  '31591',
  '31592',
  '31593',
  '31594',
  '31595',
  '31596',
  '31597',
  '31599',
  '31601',
  '31602',
  '31603',
  '31711',
  '31719',
  '31720',
  '32111',
  '32112',
  '32119',
  '32120',
  '32130',
  '32141',
  '32142',
  '32143',
  '32200',
  '32300',
  '32400',
  '32510',
  '32530',
  '32540',
  '32551',
  '32559',
  '32571',
  '32572',
  '32591',
  '32599',
  '33121',
  '33129',
  '33131',
  '33132',
  '33133',
  '33151',
  '33152',
  '33153',
  '33211',
  '33219',
  '33221',
  '33222',
  '33223',
  '33224',
  '33225',
  '33229',
  '33231',
  '33232',
  '33291',
  '33299',
  '33311',
  '33312',
  '33313',
  '33320',
  '33330',
  '33340',
  '33391',
  '33392',
  '33393',
  '33394',
  '33399',
  '33461',
  '33462',
  '33491',
  '33492',
  '33493',
  '33499',
  '33510',
  '33551',
  '33552',
  '33591',
  '33592',
  '33593',
  '33599',
  '33611',
  '33612',
  '33613',
  '33614',
  '33619',
  '34110',
  '34121',
  '34122',
  '34123',
  '34210',
  '34221',
  '34222',
  '34223',
  '34224',
  '34229',
  '34310',
  '34321',
  '34322',
  '34323',
  '34331',
  '34332',
  '34341',
  '34342',
  '34343',
  '34391',
  '34399',
  '34401',
  '34409',
  '35110',
  '35121',
  '35122',
  '35123',
  '35129',
  '35140',
  '35211',
  '35212',
  '35213',
  '35214',
  '35215',
  '35219',
  '35220',
  '35231',
  '35232',
  '35239',
  '35290',
  '36100',
  '36201',
  '36202',
  '36203',
  '36204',
  '36205',
  '36206',
  '36209',
  '36910',
  '36991',
  '36999',
  '39910',
  '40000',
  '41101',
  '41102',
  '41109',
  '41201',
  '41202',
  '41310',
  '41320',
  '42111',
  '42112',
  '42113',
  '42119',
  '42131',
  '42132',
  '42141',
  '42149',
  '42210',
  '42230',
  '42241',
  '42242',
  '42243',
  '42244',
  '42245',
  '42246',
  '42247',
  '42249',
  '42290',
  '43111',
  '43112',
  '43113',
  '43114',
  '43115',
  '43116',
  '43119',
  '43121',
  '43122',
  '43123',
  '43129',
  '43141',
  '43142',
  '43151',
  '43159',
  '43211',
  '43212',
  '43219',
  '43221',
  '43222',
  '43229',
  '43231',
  '43232',
  '43233',
  '43239',
  '44110',
  '44121',
  '44122',
  '44123',
  '44129',
  '44170',
  '44191',
  '44199',
  '51111',
  '51112',
  '51121',
  '51122',
  '51129',
  '51131',
  '51132',
  '51201',
  '51202',
  '51311',
  '51312',
  '51313',
  '51321',
  '51322',
  '51330',
  '51390',
  '51411',
  '51412',
  '51419',
  '51421',
  '51422',
  '51423',
  '51491',
  '51492',
  '51499',
  '51501',
  '51502',
  '51503',
  '51504',
  '51505',
  '51509',
  '51701',
  '51702',
  '51910',
  '51931',
  '51932',
  '51941',
  '51942',
  '51943',
  '51944',
  '51949',
  '51950',
  '51991',
  '51999',
  '52110',
  '52120',
  '52130',
  '52190',
  '52201',
  '52202',
  '52301',
  '52302',
  '52303',
  '52309',
  '52411',
  '52419',
  '52421',
  '52422',
  '52440',
  '52491',
  '52492',
  '52499',
  '53111',
  '53112',
  '53113',
  '53114',
  '53115',
  '53120',
  '53201',
  '53202',
  '53203',
  '53209',
  '54110',
  '54121',
  '54122',
  '54123',
  '54130',
  '54141',
  '54142',
  '54143',
  '54144',
  '54150',
  '54191',
  '54192',
  '54193',
  '54194',
  '54199',
  '59000',
  '61110',
  '61131',
  '61132',
  '61133',
  '61210',
  '61221',
  '61222',
  '61900',
  '62211',
  '62212',
  '62219',
  '62220',
  '71000',
  '71120',
  '71130',
  '71141',
  '71142',
  '71149',
  '71151',
  '71152',
  '71191',
  '71192',
  '71199',
  '71210',
  '71220',
  '71230',
  '71241',
  '71242',
  '71249',
  '71250',
  '71261',
  '71262',
  '71263',
  '71271',
  '71272',
  '71290',
  '71311',
  '71312',
  '71321',
  '71322',
  '71323',
  '71324',
  '71329',
  '71331',
  '71332',
  '72000',
  '72110',
  '72120',
  '72130',
  '72140',
  '72150',
  '72210',
  '72221',
  '72222',
  '72229',
  '72240',
  '72310',
  '72320',
  '72330',
  '72340',
  '72350',
  '72391',
  '72392',
  '72399',
  '73000',
  '73111',
  '73112',
  '73113',
  '73119',
  '73120',
  '73130',
  '73140',
  '73150',
  '73160',
  '73210',
  '73220',
  '74001',
  '74002',
  '74110',
  '74121',
  '74122',
  '74123',
  '74131',
  '74132',
  '74133',
  '74211',
  '74212',
  '74221',
  '74222',
  '74223',
  '74224',
  '75000',
  '75110',
  '75121',
  '75122',
  '75150',
  '75190',
  '75210',
  '75220',
  '75290',
  '75310',
  '75320',
  '75340',
  '75361',
  '75362',
  '75363',
  '75369',
  '75390',
  '75410',
  '75440',
  '75490',
  '81000',
  '81130',
  '81140',
  '81210',
  '81220',
  '81230',
  '81240',
  '81251',
  '81252',
  '81259',
  '81311',
  '81312',
  '81320',
  '81390',
  '81410',
  '81420',
  '81430',
  '81501',
  '81502',
  '81509',
  '81601',
  '81602',
  '81603',
  '81604',
  '81609',
  '81700',
  '81811',
  '81812',
  '81819',
  '81821',
  '81822',
  '81829',
  '81830',
  '81841',
  '81842',
  '81849',
  '81890',
  '82000',
  '82110',
  '82121',
  '82122',
  '82123',
  '82131',
  '82132',
  '82139',
  '82190',
  '83000',
  '83110',
  '83121',
  '83129',
  '83211',
  '83212',
  '83221',
  '83222',
  '83223',
  '83224',
  '83225',
  '83226',
  '83229',
  '83311',
  '83312',
  '83321',
  '83322',
  '83323',
  '83324',
  '83329',
  '83421',
  '83422',
  '83423',
  '83424',
  '83429',
  '83431',
  '83432',
  '83439',
  '83441',
  '83449',
  '83491',
  '83492',
  '83499',
  '83501',
  '83502',
  '83509',
  '91000',
  '91121',
  '91122',
  '91129',
  '91131',
  '91132',
  '91133',
  '91151',
  '91152',
  '91153',
  '91154',
  '91161',
  '91162',
  '91210',
  '91220',
  '91230',
  '91291',
  '91292',
  '91293',
  '91299',
  '91300',
  '92141',
  '92142',
  '92149',
  '92190',
  '93100',
  '93201',
  '93209',
  '93310',
  '93331',
  '93332',
  '93333',
  '93334',
  '93335',
  '93336',
  '93337',
  '93339',
  '94101',
  '94102',
  '94103',
  '94104',
  '96000',
  '96111',
  '96112',
  '96113',
  '96119',
  '96211',
  '96212',
  '96213',
  '96251',
  '96252',
  '96253',
  '96254',
  '96255',
  '96256',
  '96257',
  '96259',
  '96261',
  '96262',
  '96269',
  '96271',
  '96272',
  '96291',
  '96292',
  '96293',
  '96299',
  'X1000',
  'X2000',
  'X3000',
  'X4000',
  'X5000',
]
