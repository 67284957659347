import { err, ok, Result } from 'neverthrow'

export const personPRStatusValidator = (
  value: unknown,
): Result<true, string> => {
  const personPRStatusValue = String(value)

  if (!['A', 'C', 'P', 'U', 'N'].includes(personPRStatusValue)) {
    return err('Person PR status must be one of "A", "C", "P", "U", or "N"')
  }

  return ok(true)
}
