import { err, ok, Result } from 'neverthrow'

export const monthValidator = (value: unknown): Result<true, string> => {
  const monthValue = String(value)

  const regex = /^(18|19|20|21)\d{2}-(0[1-9]|1[0-2])$/
  if (!regex.test(monthValue)) {
    return err(
      'Month must be in the format of YYYY-MM, where MM is between 01 and 12',
    )
  }

  return ok(true)
}
