import { err, ok, Result } from 'neverthrow'

type VehicleStatus = '1' | '2' // or whatever type VehicleStatus is

export const VehicleStatusValidator = (
  value: unknown,
): Result<true, string> => {
  const VehicleStatusValue = value as VehicleStatus

  if (!['1', '2'].includes(VehicleStatusValue)) {
    return err('Vehicle status must be either "1" or "2"')
  }

  return ok(true)
}
