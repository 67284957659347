import { Box, Image, Text } from '@chakra-ui/react'

export const Footer = () => (
  <Box bgColor="white" h="5rem" mt="2rem" p="2rem" pt="1rem">
    <Image
      src="/smart-nation-logo.png"
      alt="GDA Logo"
      display="inline"
      h="3rem"
    />
    <Text textStyle="caption-1" color="base.content.medium" pb="2rem">
      Government Data Office, Smart Nation and Digital Government Office. Last
      Updated 23 Oct 2023.
    </Text>
  </Box>
)
