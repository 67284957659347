import { err, ok, Result } from 'neverthrow'

export const halfYearValidator = (value: unknown): Result<true, string> => {
  const regex = /^(18|19|20|21)\d{2}-(H1|H2)$/
  const stringValue = String(value)

  if (!regex.test(stringValue)) {
    return err('Half-year must be in the format of YYYY-H1 or YYYY-H2')
  }

  const yearValue = Number(stringValue.slice(0, 4))

  if (yearValue < 1800 || yearValue > 2100) {
    return err('Year must be between 1800 and 2100')
  }

  return ok(true)
}
