import { err, ok, Result } from 'neverthrow'

export const quarterValidator = (value: unknown): Result<true, string> => {
  const quarterValue = String(value)

  const regex = /^(18|19|20|21)\d{2}-(Q1|Q2|Q3|Q4)$/
  if (!regex.test(quarterValue)) {
    return err('Quarter must be in the format of YYYY-Q1/Q2/Q3/Q4')
  }

  return ok(true)
}
