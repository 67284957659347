import { Box, Flex, Icon, Text, VStack } from '@chakra-ui/react'
import { BiData } from 'react-icons/bi'

import { UploadBackgroundIllustration } from './UploadBackgroundIllustration'

export const SgDrmHeader = () => (
  <>
    <Flex
      flexDirection="row"
      justifyContent="flex-start"
      px="3rem"
      py="1rem"
      alignItems="center"
      bgColor="white"
    >
      <Icon as={BiData} boxSize="2.5rem" color="brand.primary.600" />
      <Text color="brand.primary.600" textStyle="h3">
        sg
      </Text>
      <Text color="black" textStyle="h3">
        DRM
      </Text>
    </Flex>
    <Flex
      bgColor="orange.200"
      flexDir="row"
      alignContent="center"
      justifyContent="center"
      justifyItems="center"
      alignItems="center"
      position="relative"
      height="10rem"
      px="3rem"
    >
      <Box position="absolute" zIndex={1}>
        <UploadBackgroundIllustration maxW="100%" maxH="10rem" />
      </Box>
      <VStack zIndex={2}>
        <Text textStyle="h2" color="base.content.strong" textAlign="center">
          Data quality checker
        </Text>
        <Text
          textStyle="subhead-1"
          color="base.content.medium"
          textAlign="center"
        >
          Check and comply with IM8!
        </Text>
      </VStack>
    </Flex>
  </>
)
