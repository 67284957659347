import { Box, Flex, FormControl, Text } from '@chakra-ui/react'
import { Attachment, FormErrorMessage } from '@opengovsg/design-system-react'
import { parse } from 'papaparse'
import { useEffect, useState } from 'react'

import { isErr } from '../../utils/typeguards/neverthrow'
import {
  ColumnNames,
  constructValidator,
} from '../../utils/validators/validator.factory'

export const CsvChecker = () => {
  const [file, setFile] = useState<File | undefined>(undefined)
  const [fileErrorMessage, setFileErrorMessage] = useState<string | undefined>(
    undefined,
  )

  const [results, setResults] = useState<string[]>([])
  const [headerWarnings, setHeaderWarnings] = useState<string[]>([])

  const onChange = (file?: File) => {
    if (!file) {
      setFile(undefined)
      setResults([])
    }
    setFileErrorMessage(undefined)
    setFile(file)
  }

  /**
   * Sets the error message to be displayed
   * @param errMsg
   */
  const onError = (errMsg: string) => {
    setFileErrorMessage(errMsg)
  }

  const onFileValidation = (file: File) => {
    if (file.name.endsWith('.csv')) {
      return null
    }
    return 'Only .csv files are accepted.'
  }

  useEffect(() => {
    if (!file) {
      return
    }
    const reader = new FileReader()
    reader.onload = (event) => {
      setResults([])
      setHeaderWarnings([])
      const contents = event.target?.result as string
      const parsed = parse(contents, { header: false })

      const { data: dataArr } = parsed

      const headerWarnings: string[] = []
      const dataResults = []

      const headerArr = dataArr[0] as unknown[]

      headerArr.forEach((header) => {
        const headerStr = String(header)
        if (!(headerStr in ColumnNames)) {
          headerWarnings.push(
            `"${headerStr}" is not a valid column name. Validation skipped for this column.`,
          )
        }
      })

      setHeaderWarnings(headerWarnings)

      const validatorArr = headerArr.map((header) => {
        return constructValidator(header)
      })

      const resultsArr = dataArr.slice(1)

      for (let i = 0; i < resultsArr.length; i++) {
        const row = resultsArr[i] as unknown[]

        const validationResults = row.map((value, idx) => {
          return validatorArr[idx](value).mapErr(
            (errMsg) => `${errMsg}. The value "${String(value)}" is invalid.`,
          )
        })

        dataResults.push(
          ...validationResults
            .filter(isErr)
            .map((result) => `Row ${i + 1}: ${result.error}`),
        )
      }
      setResults(dataResults)
    }
    reader.readAsText(file as Blob)
  }, [file])

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      mx="auto"
      width="min-content"
      gap="0.5rem"
      pt="2rem"
      height="max-content"
    >
      <Text textStyle="caption-1">UPLOAD YOUR .CSV FILE TO GET STARTED</Text>
      <Text textStyle="caption-2" textColor="base.content.medium">
        The checker runs in your browser and files never leave your local
        machine.
      </Text>
      {/* calculate width to be either 80% of page or else 40rem at max */}
      <Box width={'min(80vw, 40rem)'} mt="1rem">
        <Attachment
          onChange={onChange}
          name="upload"
          value={file}
          colorScheme="blackAlpha"
          onFileValidation={onFileValidation}
          onError={onError}
        />
        {/* Displays error message if any */}
        <FormControl isInvalid={!!fileErrorMessage}>
          <FormErrorMessage>{fileErrorMessage}</FormErrorMessage>
        </FormControl>
        <Box
          mt="2rem"
          p="1rem"
          display={results.length > 0 ? 'block' : 'none'}
          bgColor="orange.50"
          borderRadius="0.25rem"
          borderWidth="0.0625rem"
          borderColor="orange.200"
        >
          <Text textStyle="subhead-1" mb="1rem" color="base.content.brand">
            Results
          </Text>
          {/* Validation failures */}
          <Box>
            {results.length > 0 &&
              results.map((result, idx) => (
                <Text
                  textStyle="caption-2"
                  textColor="base.content.medium"
                  key={idx}
                >
                  {result}
                </Text>
              ))}
          </Box>
          {/* Header warnings */}
          <Box
            display={
              results.length > 0 && headerWarnings.length > 0 ? 'block' : 'none'
            }
          >
            <Text
              textStyle="subhead-2"
              mb="1rem"
              mt="2rem"
              color="utility.feedback.critical"
            >
              Warnings
            </Text>
            {results.length > 0 &&
              headerWarnings.length > 0 &&
              headerWarnings.map((warning, idx) => (
                <Text
                  textStyle="caption-2"
                  color="utility.feedback.critical"
                  key={idx}
                >
                  {warning}
                </Text>
              ))}
          </Box>
        </Box>
      </Box>
    </Flex>
  )
}
