import { err, ok, Result } from 'neverthrow'

export const personLifeStatusValidator = (
  value: unknown,
): Result<true, string> => {
  const personLifeStatusValue = String(value)

  if (!['A', 'D'].includes(personLifeStatusValue)) {
    return err('Person life status must be one of "A" or "D"')
  }

  return ok(true)
}
