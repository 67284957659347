import { businessActivityValidator } from './businessActivityValidator'
import { coeCategoryValidator } from './coeBidCatValidator'
import { dateValidator } from './dateValidator'
import { defaultValidator } from './defaultValidator'
import { emailValidator } from './emailValidator'
import { halfYearValidator } from './halfyearValidator'
import { lifeStatusValidator } from './lifeStatusValidator'
import { monthValidator } from './monthValidator'
import { personLifeStatusValidator } from './personLifeStatusValidator'
import { personOccupationValidator } from './personOccupationValidator'
import { personPRStatusValidator } from './personPRStatusValidator'
import { quarterValidator } from './quarterValidator'
import { vehicleOwnerIDValidator } from './vehicleOwnerIDValidator'
import { VehicleStatusValidator } from './vehicleStatusValidator'
import { yearValidator } from './yearValidator'

export enum ColumnNames {
  Year = 'Year',
  LifeStatus = 'PersonLifeStatus',
  HalfYear = 'Half-year',
  Quarter = 'Quarter',
  COECategory = 'COE_Category',
  VehicleStatus = 'VehicleStatus',
  FinancialHalfYear = 'Financial half-year',
  FinancialQuarter = 'Financial quarter',
  FinancialYear = 'Financial year',
  Month = 'Month',
  PersonOccupation = 'PersonOccupation',
  BusinessActivity = 'BusinessActivity',
  PersonPRStatus = 'PersonPRStatus',
  Email = 'Email',
  PersonLifeStatus = 'PersonLifeStatus',
  VehicleOwnerIDType = 'VehicleOwnerIDType',
  Date = 'Date',
}

/**
 * Factory function that returns the correct validator based on the column name
 * @param columnName
 * @returns validator function
 */
export const constructValidator = (
  columnName: unknown,
): typeof yearValidator | typeof defaultValidator => {
  const columnNameStr = String(columnName)
  switch (columnNameStr) {
    case ColumnNames.Year:
    case ColumnNames.FinancialYear:
      return yearValidator
    case ColumnNames.LifeStatus:
      return lifeStatusValidator
    case ColumnNames.HalfYear:
    case ColumnNames.FinancialHalfYear:
      return halfYearValidator
    case ColumnNames.Month:
      return monthValidator
    case ColumnNames.Quarter:
    case ColumnNames.FinancialQuarter:
      return quarterValidator
    case ColumnNames.COECategory:
      return coeCategoryValidator
    case ColumnNames.PersonOccupation:
      return personOccupationValidator
    case ColumnNames.BusinessActivity:
      return businessActivityValidator
    case ColumnNames.VehicleStatus:
      return VehicleStatusValidator
    case ColumnNames.PersonPRStatus:
      return personPRStatusValidator
    case ColumnNames.Email:
      return emailValidator
    case ColumnNames.PersonLifeStatus:
      return personLifeStatusValidator
    case ColumnNames.VehicleOwnerIDType:
      return vehicleOwnerIDValidator
    case ColumnNames.Date:
      return dateValidator
    default:
      return defaultValidator
  }
}
