import { err, ok, Result } from 'neverthrow'

export const businessActivityValidator = (
  value: unknown,
): Result<true, string> => {
  const businessActivityValue = String(value)

  if (!BUSINESS_ACTIVITY_SSIC_CODES.includes(businessActivityValue)) {
    return err('Business activity must have a valid SSIC code.')
  }

  return ok(true)
}

const BUSINESS_ACTIVITY_SSIC_CODES = [
  '01111',
  '01112',
  '01113',
  '01119',
  '01120',
  '01130',
  '01141',
  '01142',
  '01149',
  '01190',
  '01411',
  '01412',
  '01413',
  '01421',
  '01422',
  '01423',
  '01424',
  '01491',
  '01492',
  '01493',
  '01494',
  '01499',
  '01500',
  '01600',
  '02000',
  '03101',
  '03102',
  '03103',
  '03109',
  '03201',
  '03202',
  '03209',
  '08101',
  '08102',
  '08900',
  '09001',
  '09002',
  '10101',
  '10102',
  '10103',
  '10104',
  '10109',
  '10200',
  '10301',
  '10302',
  '10303',
  '10401',
  '10402',
  '10409',
  '10501',
  '10502',
  '10503',
  '10509',
  '10611',
  '10612',
  '10613',
  '10619',
  '10620',
  '10711',
  '10712',
  '10719',
  '10720',
  '10731',
  '10732',
  '10733',
  '10740',
  '10750',
  '10761',
  '10762',
  '10763',
  '10764',
  '10765',
  '10791',
  '10792',
  '10793',
  '10794',
  '10799',
  '10800',
  '11010',
  '11020',
  '11030',
  '11041',
  '11042',
  '11043',
  '12001',
  '12009',
  '13100',
  '13910',
  '13921',
  '13922',
  '13929',
  '13930',
  '13940',
  '13990',
  '14101',
  '14102',
  '14103',
  '14109',
  '14200',
  '14301',
  '14302',
  '14309',
  '15110',
  '15121',
  '15122',
  '15129',
  '15200',
  '16101',
  '16102',
  '16109',
  '16211',
  '16219',
  '16291',
  '16292',
  '16293',
  '16294',
  '16295',
  '16299',
  '17010',
  '17021',
  '17022',
  '17091',
  '17092',
  '17093',
  '17094',
  '17099',
  '18111',
  '18112',
  '18113',
  '18114',
  '18115',
  '18119',
  '18121',
  '18122',
  '18129',
  '18200',
  '19100',
  '19201',
  '19202',
  '19209',
  '20111',
  '20112',
  '20113',
  '20114',
  '20119',
  '20120',
  '20131',
  '20132',
  '20133',
  '20210',
  '20221',
  '20222',
  '20229',
  '20231',
  '20232',
  '20233',
  '20234',
  '20241',
  '20242',
  '20249',
  '20291',
  '20292',
  '20293',
  '20294',
  '20295',
  '20296',
  '20297',
  '20298',
  '20299',
  '20300',
  '21011',
  '21012',
  '21013',
  '21021',
  '21022',
  '21023',
  '21030',
  '22111',
  '22112',
  '22119',
  '22121',
  '22122',
  '22191',
  '22192',
  '22193',
  '22194',
  '22199',
  '22211',
  '22212',
  '22213',
  '22214',
  '22215',
  '22216',
  '22217',
  '22218',
  '22219',
  '22220',
  '23100',
  '23911',
  '23912',
  '23919',
  '23931',
  '23939',
  '23940',
  '23951',
  '23959',
  '23960',
  '23991',
  '23992',
  '23993',
  '23994',
  '23995',
  '23999',
  '24101',
  '24102',
  '24109',
  '24201',
  '24202',
  '24209',
  '24310',
  '24320',
  '25111',
  '25112',
  '25113',
  '25114',
  '25119',
  '25120',
  '25131',
  '25139',
  '25200',
  '25911',
  '25912',
  '25913',
  '25914',
  '25919',
  '25921',
  '25922',
  '25923',
  '25924',
  '25929',
  '25931',
  '25932',
  '25939',
  '25941',
  '25949',
  '25951',
  '25952',
  '25959',
  '25991',
  '25992',
  '25993',
  '25994',
  '25995',
  '25996',
  '25997',
  '25998',
  '25999',
  '26111',
  '26112',
  '26113',
  '26114',
  '26115',
  '26119',
  '26121',
  '26122',
  '26123',
  '26124',
  '26125',
  '26126',
  '26129',
  '26201',
  '26202',
  '26203',
  '26204',
  '26209',
  '26301',
  '26302',
  '26303',
  '26304',
  '26309',
  '26400',
  '26511',
  '26512',
  '26513',
  '26521',
  '26522',
  '26523',
  '26524',
  '26601',
  '26602',
  '26701',
  '26709',
  '26801',
  '26802',
  '27101',
  '27102',
  '27103',
  '27104',
  '27109',
  '27201',
  '27202',
  '27321',
  '27322',
  '27323',
  '27324',
  '27329',
  '27330',
  '27400',
  '27501',
  '27509',
  '27901',
  '27902',
  '28111',
  '28112',
  '28121',
  '28122',
  '28129',
  '28141',
  '28142',
  '28150',
  '28161',
  '28162',
  '28169',
  '28171',
  '28172',
  '28181',
  '28182',
  '28189',
  '28191',
  '28192',
  '28193',
  '28194',
  '28195',
  '28199',
  '28210',
  '28221',
  '28222',
  '28223',
  '28224',
  '28225',
  '28229',
  '28241',
  '28242',
  '28243',
  '28249',
  '28251',
  '28252',
  '28253',
  '28260',
  '28271',
  '28272',
  '28273',
  '28274',
  '28291',
  '28292',
  '28293',
  '28294',
  '28299',
  '28300',
  '29100',
  '29200',
  '29300',
  '30110',
  '30120',
  '30200',
  '30301',
  '30302',
  '30400',
  '30910',
  '30920',
  '30990',
  '31001',
  '31002',
  '31003',
  '31004',
  '31005',
  '31006',
  '31009',
  '32111',
  '32112',
  '32119',
  '32120',
  '32200',
  '32300',
  '32400',
  '32501',
  '32502',
  '32503',
  '32504',
  '32509',
  '32901',
  '32909',
  '35101',
  '35102',
  '35103',
  '35104',
  '35201',
  '35202',
  '35203',
  '35300',
  '36000',
  '37000',
  '38100',
  '38200',
  '38301',
  '38309',
  '41001',
  '41002',
  '41009',
  '42101',
  '42102',
  '42103',
  '42201',
  '42202',
  '42901',
  '42902',
  '42903',
  '42909',
  '43110',
  '43121',
  '43122',
  '43129',
  '43210',
  '43220',
  '43291',
  '43292',
  '43293',
  '43294',
  '43295',
  '43296',
  '43299',
  '43301',
  '43302',
  '43303',
  '43304',
  '43305',
  '43306',
  '43307',
  '43309',
  '43901',
  '43902',
  '43903',
  '43904',
  '43905',
  '43906',
  '43907',
  '43909',
  '46100',
  '46211',
  '46212',
  '46213',
  '46219',
  '46221',
  '46222',
  '46223',
  '46224',
  '46225',
  '46229',
  '46301',
  '46302',
  '46303',
  '46304',
  '46305',
  '46306',
  '46307',
  '46308',
  '46309',
  '46411',
  '46412',
  '46413',
  '46414',
  '46415',
  '46416',
  '46421',
  '46422',
  '46423',
  '46424',
  '46429',
  '46431',
  '46432',
  '46433',
  '46434',
  '46435',
  '46436',
  '46437',
  '46439',
  '46441',
  '46442',
  '46443',
  '46444',
  '46449',
  '46451',
  '46452',
  '46453',
  '46459',
  '46461',
  '46462',
  '46471',
  '46472',
  '46473',
  '46474',
  '46479',
  '46491',
  '46492',
  '46499',
  '46511',
  '46512',
  '46513',
  '46514',
  '46521',
  '46522',
  '46523',
  '46530',
  '46541',
  '46542',
  '46543',
  '46544',
  '46549',
  '46551',
  '46552',
  '46559',
  '46561',
  '46562',
  '46563',
  '46591',
  '46592',
  '46593',
  '46594',
  '46595',
  '46599',
  '46610',
  '46620',
  '46631',
  '46632',
  '46633',
  '46634',
  '46635',
  '46639',
  '46641',
  '46642',
  '46643',
  '46649',
  '46651',
  '46659',
  '46661',
  '46662',
  '46900',
  '47101',
  '47102',
  '47103',
  '47109',
  '47211',
  '47212',
  '47213',
  '47214',
  '47219',
  '47220',
  '47230',
  '47311',
  '47312',
  '47313',
  '47314',
  '47320',
  '47411',
  '47412',
  '47413',
  '47414',
  '47415',
  '47420',
  '47510',
  '47521',
  '47522',
  '47529',
  '47531',
  '47532',
  '47533',
  '47534',
  '47535',
  '47536',
  '47537',
  '47539',
  '47610',
  '47620',
  '47630',
  '47640',
  '47711',
  '47712',
  '47713',
  '47714',
  '47715',
  '47719',
  '47721',
  '47722',
  '47723',
  '47729',
  '47731',
  '47732',
  '47733',
  '47739',
  '47741',
  '47742',
  '47743',
  '47744',
  '47745',
  '47746',
  '47749',
  '47751',
  '47752',
  '47759',
  '47761',
  '47762',
  '47769',
  '47770',
  '47801',
  '47802',
  '49101',
  '49102',
  '49211',
  '49212',
  '49213',
  '49214',
  '49215',
  '49220',
  '49231',
  '49232',
  '49233',
  '49300',
  '50011',
  '50012',
  '50013',
  '50014',
  '50021',
  '50022',
  '50023',
  '51001',
  '51002',
  '51003',
  '51004',
  '51005',
  '52101',
  '52102',
  '52103',
  '52109',
  '52211',
  '52212',
  '52219',
  '52221',
  '52222',
  '52223',
  '52224',
  '52225',
  '52226',
  '52227',
  '52229',
  '52231',
  '52232',
  '52233',
  '52234',
  '52239',
  '52241',
  '52242',
  '52243',
  '52251',
  '52252',
  '52291',
  '52292',
  '53100',
  '53200',
  '55101',
  '55102',
  '55103',
  '55900',
  '56111',
  '56112',
  '56121',
  '56122',
  '56123',
  '56130',
  '56140',
  '56200',
  '58110',
  '58120',
  '58131',
  '58132',
  '58190',
  '58201',
  '58202',
  '59111',
  '59112',
  '59113',
  '59114',
  '59119',
  '59120',
  '59131',
  '59132',
  '59139',
  '59140',
  '59201',
  '59202',
  '60100',
  '60200',
  '61011',
  '61012',
  '61013',
  '61091',
  '61092',
  '61099',
  '62011',
  '62012',
  '62013',
  '62021',
  '62022',
  '62023',
  '62090',
  '63111',
  '63112',
  '63119',
  '63120',
  '63201',
  '63202',
  '63203',
  '63204',
  '63205',
  '63209',
  '63901',
  '63909',
  '64110',
  '64120',
  '64130',
  '64140',
  '64150',
  '64190',
  '64201',
  '64202',
  '64300',
  '64910',
  '64921',
  '64922',
  '64923',
  '64924',
  '64925',
  '64929',
  '64991',
  '64992',
  '64993',
  '64994',
  '64999',
  '65110',
  '65121',
  '65122',
  '65123',
  '65124',
  '65201',
  '65202',
  '65301',
  '65302',
  '66110',
  '66121',
  '66122',
  '66123',
  '66124',
  '66125',
  '66126',
  '66129',
  '66191',
  '66192',
  '66193',
  '66194',
  '66195',
  '66199',
  '66210',
  '66221',
  '66222',
  '66291',
  '66292',
  '66299',
  '66301',
  '66302',
  '66303',
  '66304',
  '66305',
  '66306',
  '66307',
  '66309',
  '68101',
  '68102',
  '68103',
  '68104',
  '68105',
  '68106',
  '68201',
  '68202',
  '68203',
  '68209',
  '69101',
  '69102',
  '69201',
  '69202',
  '70101',
  '70102',
  '70201',
  '70202',
  '70203',
  '70204',
  '70205',
  '70206',
  '70209',
  '71111',
  '71112',
  '71113',
  '71114',
  '71119',
  '71121',
  '71122',
  '71123',
  '71124',
  '71125',
  '71126',
  '71129',
  '71200',
  '72101',
  '72102',
  '72103',
  '72104',
  '72105',
  '72106',
  '72109',
  '72200',
  '73100',
  '73200',
  '74111',
  '74112',
  '74113',
  '74119',
  '74191',
  '74192',
  '74193',
  '74194',
  '74199',
  '74200',
  '74901',
  '74902',
  '74903',
  '74904',
  '74905',
  '74909',
  '75000',
  '77101',
  '77102',
  '77109',
  '77210',
  '77220',
  '77291',
  '77292',
  '77299',
  '77310',
  '77320',
  '77330',
  '77341',
  '77342',
  '77391',
  '77392',
  '77399',
  '77400',
  '78101',
  '78102',
  '78103',
  '78104',
  '78300',
  '79101',
  '79102',
  '79200',
  '80100',
  '80200',
  '81211',
  '81212',
  '81291',
  '81292',
  '81299',
  '81300',
  '82110',
  '82191',
  '82192',
  '82199',
  '82200',
  '82301',
  '82302',
  '82303',
  '82304',
  '82911',
  '82912',
  '82920',
  '82991',
  '82999',
  '84110',
  '84120',
  '84210',
  '84221',
  '84229',
  '84231',
  '84232',
  '84233',
  '84234',
  '84235',
  '84239',
  '85101',
  '85102',
  '85211',
  '85212',
  '85213',
  '85220',
  '85230',
  '85240',
  '85250',
  '85301',
  '85302',
  '85303',
  '85304',
  '85401',
  '85402',
  '85403',
  '85404',
  '85405',
  '85406',
  '85407',
  '85408',
  '85409',
  '85501',
  '85502',
  '85509',
  '86101',
  '86102',
  '86109',
  '86201',
  '86202',
  '86203',
  '86204',
  '86901',
  '86902',
  '86903',
  '86904',
  '86905',
  '86909',
  '87010',
  '87021',
  '87022',
  '87029',
  '88101',
  '88102',
  '88911',
  '88912',
  '88913',
  '88919',
  '88920',
  '88991',
  '88992',
  '88999',
  '90001',
  '90002',
  '90003',
  '90009',
  '91010',
  '91021',
  '91029',
  '91030',
  '92001',
  '92002',
  '93111',
  '93119',
  '93120',
  '93201',
  '93202',
  '93203',
  '93204',
  '93209',
  '94110',
  '94120',
  '94200',
  '94911',
  '94912',
  '94913',
  '94914',
  '94919',
  '94920',
  '94991',
  '94992',
  '94993',
  '94994',
  '94995',
  '94999',
  '95110',
  '95120',
  '95210',
  '95220',
  '95230',
  '95240',
  '95291',
  '95292',
  '95293',
  '95299',
  '95301',
  '95302',
  '95303',
  '96011',
  '96012',
  '96021',
  '96022',
  '96023',
  '96024',
  '96029',
  '96030',
  '96040',
  '96091',
  '96092',
  '96093',
  '96094',
  '96095',
  '96099',
  '97001',
  '97002',
  '99010',
  '99020',
  '99090',
]
