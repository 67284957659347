import { Box, Flex } from '@chakra-ui/react'
import { RestrictedGovtMasthead } from '@opengovsg/design-system-react'

import { CsvChecker } from '../components/CsvChecker/CsvChecker'
import { Footer } from '../components/Footer/Footer'
import { SgDrmHeader } from '../components/SgDrmHeader/SgDrmHeader'
export const MainPage = () => {
  return (
    <Flex
      w="100%"
      bgColor="#FEFBF8"
      height="max-content"
      minH="100vh"
      flexDir="column"
      justifyContent="space-between"
    >
      <Box>
        <RestrictedGovtMasthead />
        <SgDrmHeader />
        <CsvChecker />
      </Box>
      <Footer />
    </Flex>
  )
}
