import { err, ok, Result } from 'neverthrow'

export const coeCategoryValidator = (value: unknown): Result<true, string> => {
  const COECategoryValue = String(value)

  if (!['A', 'B', 'C', 'D', 'E'].includes(COECategoryValue)) {
    return err('COE bid category must be one of "A", "B", "C", "D", or "E"')
  }

  return ok(true)
}
