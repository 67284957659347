import { validate } from 'email-validator'
import { err, ok, Result } from 'neverthrow'

export const emailValidator = (value: unknown): Result<true, string> => {
  const stringValue = String(value)

  if (!validate(stringValue)) {
    return err('Email must be in valid format')
  }

  return ok(true)
}
