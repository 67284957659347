import { err, ok, Result } from 'neverthrow'

export const vehicleOwnerIDValidator = (
  value: unknown,
): Result<true, string> => {
  const vehicleOwnIDValue = String(value)

  if (!VEHICLE_OWNER_ID_CODES.includes(vehicleOwnIDValue)) {
    return err(
      'Vehicle owner ID type must have value between 1 to 9 or A, B or C.',
    )
  }

  return ok(true)
}

const VEHICLE_OWNER_ID_CODES = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'A',
  'B',
  'C',
]
