import moment from 'moment'
import { err, ok, Result } from 'neverthrow'

export const dateValidator = (value: unknown): Result<true, string> => {
  const dateValue = String(value)

  const regex = /^(18|19|20|21)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/
  if (!regex.test(dateValue)) {
    return err('Date must be in the format of YYYY-MM-DD')
  }

  const date = moment(dateValue, 'YYYY-MM-DD')
  if (!date.isValid() || date.year() < 1800 || date.year() > 2100) {
    return err('Date must be between 1800-2100')
  }

  return ok(true)
}
