import { err, ok, Result } from 'neverthrow'

export const yearValidator = (value: unknown): Result<true, string> => {
  const yearValue = Number(value)

  if (yearValue < 1800 || yearValue > 2100) {
    return err('Year must be between 1800 and 2100')
  }
  return ok(true)
}
